<template>
    <default-layout class="authPage">
        <div class="pageBody">
            <h1 class="pageTitle">Log in</h1>
            <h2 class="pageSubtitle">for the latest scoop.</h2>
            <GoogleLoginButton @success="login" />
            <p>
                No account?
                <router-link :to="{ name: 'signup' }">
                    Sign up for free
                </router-link>
            </p>
        </div>
        <AccountSelectModal
            :opened="showAccountSelectModal"
            :accounts="accounts"
            @select="loginWithAccount"
            @close="showAccountSelectModal = !showAccountSelectModal"
        />
    </default-layout>
</template>

<script>
import AccountSelectModal from '@/components/Modal/AccountSelectModal'
import GoogleLoginButton from '@/components/Button/GoogleLoginButton.vue'
import { googleAuth } from '~/mixins/googleAuth'

export default {
    name: 'Login',
    components: { AccountSelectModal, GoogleLoginButton },
    mixins: [googleAuth],
}
</script>
