<template>
    <b-modal class="accSelectModal" has-modal-card :active="opened">
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">
                    Pick the SessionScoop account
                </p>
            </header>
            <section class="modal-card-body">
                <ul>
                    <li
                        v-for="(account, index) in accounts"
                        :key="index"
                        @click="select(account)"
                    >
                        <span>
                            {{ account.name }}
                            <b-tag class="is-primary" v-if="account.isPrimary">
                                Primary
                            </b-tag>
                        </span>
                        <i class="fas fa-arrow-circle-right"></i>
                    </li>
                </ul>
            </section>
            <footer class="modal-card-foot">
                <button class="button" @click="close">Cancel</button>
            </footer>
        </div>
    </b-modal>
</template>

<script>
export default {
    name: 'AccountSelectModal',
    props: {
        opened: {
            type: Boolean,
        },
        accounts: {
            type: Array,
        },
    },
    methods: {
        select(account) {
            this.$emit('select', account.id)
        },
        close() {
            this.$emit('close')
        },
    },
}
</script>
